import React, { useState, useCallback, useEffect } from 'react'
import { database } from '../../firebase'
import departments from '../constants/departments'
import moment from 'moment'
import 'moment/locale/es'
import { CSVLink, CSVDownload } from "react-csv"
import { Navbar, Nav, Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import ResponsiveBreakPoints from '../utilities/ResponsiveBreakPoints'

export default function NavBarComponent({
  dashboardType,
  listState
}) {
  const isPurchaseDash = dashboardType === 'purchases'
  const isQuoteDash = dashboardType === 'quotes'
  const isExchangeDash = dashboardType === 'exchanges'
  const isWarrantyDash = dashboardType === 'warranties'
  const isReturnDash = dashboardType === 'returns'
  const isRingSizesDash = dashboardType === 'ringSizes'
  const isMaintenancesDash = dashboardType === 'maintenances'
  const [tsvData, setTsvData] = useState([])

  useEffect(() => {
    var tsvArrHeader = [[
      'Nit',
      'División',
      'Nombre',
      'Dirección',
      'Teléfono',
      'Ciudad Destino'
    ]]

    const tsvArr = listState.reduce((arr, listItem, idx) => {
      const listItemData = listItem.data()
      if (listItemData.status === 'ready') {
        const section = listItemData.diffShippingInfo ? 'shipping' : 'billing'
        const fullName = [
          listItemData[section + 'FirstName'],
          ...(listItemData[section + 'MiddleName'] !== '' ? [listItemData[section + 'MiddleName']] : []),
          listItemData[section + 'LastName']
        ].join(' ')
        const department = departments.filter(d => d.department === listItemData[section + 'Department'].department)[0].code
        const arrx = [
          listItemData.idNumber,
          1,
          fullName,
          listItemData[section + 'Address'],
          listItemData[section + 'Phone'],
          `${listItemData[section + 'City']} (${department})`
        ]

        return arr.concat([arrx])
      } else {
        return arr
      }
    }, tsvArrHeader)

    setTsvData(tsvArr)

  }, [listState])

  return (
    <Navbar className="NavBarComponent" fixed="top" bg="light" expand="sm">
      {/* <Navbar.Brand as={Link} to="/compras">
        NG Sales
      </Navbar.Brand> */}
      <Nav>
        <Nav.Link className={isPurchaseDash ? 'NavBarComponent-link--active' : ''} as={Link} to="/purchases-dashboard">Compras</Nav.Link>
        <Nav.Link className={isQuoteDash ? 'NavBarComponent-link--active' : ''} as={Link} to="/quotes-dashboard">Cotizaciones</Nav.Link>
        <Nav.Link className={isExchangeDash ? 'NavBarComponent-link--active' : ''} as={Link} to="/exchanges-dashboard">Cambios</Nav.Link>
        <Nav.Link className={isWarrantyDash ? 'NavBarComponent-link--active' : ''} as={Link} to="/warranties-dashboard">Garantías</Nav.Link>
        <Nav.Link className={isReturnDash ? 'NavBarComponent-link--active' : ''} as={Link} to="/returns-dashboard">Devoluciones</Nav.Link>
        <Nav.Link className={isRingSizesDash ? 'NavBarComponent-link--active' : ''} as={Link} to="/ringSizes-dashboard">Medidas de Anillos</Nav.Link>
        <Nav.Link className={isMaintenancesDash ? 'NavBarComponent-link--active' : ''} as={Link} to="/maintenances-dashboard">Mantenimientos</Nav.Link>
        {/* <Nav.Link as={Link} to="/profile">
          Profile
        </Nav.Link> */}
      </Nav>

      {isPurchaseDash && <div>
        {/* <Button
          size="sm"
          variant="ng-primary"
          onClick={handleTSV}
        >
          TSV
        </Button> */}
        <CSVLink
          as="button"
          data={tsvData}
          enclosingCharacter={``}
          separator={"  "}
          filename={`${moment().format("dddd_DD_MMMM_YYYY_hh_mm_ss_a")}.tsv`}
        >
          TSV
        </CSVLink>
      </div>}
      {/* <ResponsiveBreakPoints className="ml-auto" /> */}
    </Navbar>
  )
}
