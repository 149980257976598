import React from "react"
import includes from 'lodash/includes'
import { BrowserRouter as Router, Switch, Redirect, Route } from "react-router-dom"
import { AuthProvider } from "../contexts/AuthContext"
import PrivateRoute from "./authentication/PrivateRoute"
import Signup from "./authentication/Signup"
import Login from "./authentication/Login"
import Profile from "./authentication/Profile"
import UpdateProfile from "./authentication/UpdateProfile"
import ForgotPassword from "./authentication/ForgotPassword"
import PurchaseForm from "./forms/PurchaseForm"
import QuoteForm from "./forms/QuoteForm"
import ExchangeForm from "./forms/ExchangeForm"
import WarrantyForm from "./forms/WarrantyForm"
import ReturnForm from "./forms/ReturnForm"
import RingSizeForm from "./forms/RingSizeForm"
import MaintenanceForm from "./forms/MaintenanceForm"

import PurchasesDashboard from "./dashboards/PurchasesDashboard"
import QuotesDashboard from "./dashboards/QuotesDashboard"
import ExchangesDashboard from "./dashboards/ExchangesDashboard"
import WarrantiesDashboard from "./dashboards/WarrantiesDashboard"
import ReturnsDashboard from "./dashboards/ReturnsDashboard"
import RingSizesDashboard from "./dashboards/RingSizesDashboard"
import MaintenancesDashboard from "./dashboards/MaintenancesDashboard"

export default function App() {
  const subdomain = window.location.host.split('.')[0]
  const isLocal = includes(subdomain, 'localhost')
  const isPurchase = subdomain === 'compras'
  const isQuote = subdomain === 'cotizaciones'
  const isExchange = subdomain === 'cambios'
  const isWarranty = subdomain === 'garantias'
  const isReturn = subdomain === 'devoluciones'
  const isRingSize = subdomain === 'medidas'
  const isMaintenance = subdomain === 'mantenimiento'
  const isDashboard = isLocal || subdomain === 'app'

  return (
    <Router>
      <AuthProvider>
        <Switch>
          {(isDashboard || isLocal) && <Redirect exact from="/" to="/login" />}

          {/* Forms */}
          <Route exact={isPurchase} path={isPurchase ? "/" : "/compras"} component={PurchaseForm} />
          <Route exact={isQuote} path={isQuote ? "/" : "/cotizaciones"} component={QuoteForm} />
          <Route exact={isExchange} path={isExchange ? "/" : "/cambios"} component={ExchangeForm} />
          <Route exact={isWarranty} path={isWarranty ? "/" : "/garantias"} component={WarrantyForm} />
          <Route exact={isReturn} path={isReturn ? "/" : "/devoluciones"} component={ReturnForm} />
          <Route exact={isRingSize} path={isRingSize ? "/" : "/medidas"} component={RingSizeForm} />
          <Route exact={isMaintenance} path={isMaintenance ? "/" : "/mantenimiento"} component={MaintenanceForm} />

          {/* Auth */}
          <Route path="/signup" component={Signup} />
          <Route path="/login" component={Login} />
          <Route path="/forgot-password" component={ForgotPassword} />

          {/* Dashboards */}
          <PrivateRoute path="/purchases-dashboard" component={PurchasesDashboard} />
          <PrivateRoute path="/quotes-dashboard" component={QuotesDashboard} />
          <PrivateRoute path="/exchanges-dashboard" component={ExchangesDashboard} />
          <PrivateRoute path="/warranties-dashboard" component={WarrantiesDashboard} />
          <PrivateRoute path="/returns-dashboard" component={ReturnsDashboard} />
          <PrivateRoute path="/ringSizes-dashboard" component={RingSizesDashboard} />
          <PrivateRoute path="/maintenances-dashboard" component={MaintenancesDashboard} />

          {/* Profile */}
          <PrivateRoute path="/profile" component={Profile} />
          <PrivateRoute path="/update-profile" component={UpdateProfile} />
        </Switch>
      </AuthProvider>
    </Router>
  )
}
