import React, { useState, useMemo, useEffect } from 'react'
import { database } from '../../firebase'
// import { useAuth } from "../../contexts/AuthContext"
import copy from "../constants/copy"
import moment from 'moment'
import 'moment/locale/es'
import Pluralize from 'pluralize'
import {
  Card,
  Row,
  Col,
  Alert,
  Image
} from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faLaptop,
  faMobileAlt,
  faPhoneSquareAlt,
  faCameraRetro,
  faDove,
  faIdCard,
  faPaperPlane,
  faFileSignature,
  faExclamationCircle,
  faRulerHorizontal
} from '@fortawesome/free-solid-svg-icons'
import InfoCard from '../cards/InfoCard'
import ImageModal from '../modals/ImageModal'
import StatusFormGroup from "../formGroups/StatusFormGroup"
import RingSizeFormGroup from "../formGroups/RingSizeFormGroup"

export default function DashboardListItem({
  dashboardType,
  listItem,
  idx,
  listState,
  setListState
}) {
  const isPurchases = dashboardType === 'purchases'
  const isRingSizesDash = dashboardType === 'ringSizes'
  // const isPostsale = dashboardType === 'exchanges' || dashboardType === 'warranties' || dashboardType === 'maintenances'
  const isPostsale = ['exchanges', 'warranties', 'maintenances'].includes(dashboardType)
  const section = isPurchases ? 'billing' : Pluralize(dashboardType, 1)
  // const classModifier = isPurchases ? 'ng-primary' : 'ng-secondary'
  const classModifier = 'ng-primary'
  const listItemData = listItem.data()
  const [initFlag, setInitFlag] = useState(true)
  const [localTimeState, setLocalTimeState] = useState('')
  const [showImageModal, setShowImageModal] = useState(false)
  // const [status, setStatus] = useState('pending')
  // const { currentUser } = useAuth()
  const [formState, setFormState] = useState(listItemData)

  // const customerRef = database.customers.doc(listItemData.idNumber)
  // const listItemRef = customerRef.collection(dashboardType).doc(listItem.id)

  const channelIcon = useMemo(() => {
    switch (listItemData.contactChannel) {
      case 'whatsapp':
        return faPhoneSquareAlt
      case 'instagram':
        return faCameraRetro
      case 'twitter':
        return faDove
      case 'facebook':
        return faIdCard
      case 'Telegram':
        return faPaperPlane
      case 'other':
        return faFileSignature
      default:
        return faExclamationCircle
    }
  }, [listItemData])

  // const handleStateChange = useCallback((e) => {
  //   console.log('*** handleStateChange:', e.target.value)
  //   console.log('*** currentUser:', currentUser.email)
  //   // console.log('*** status:', status)
  //   setStatus(e.target.value)
  // }, [])

  useEffect(() => {
    // if (idx == 0) {
    //   console.log('*** listItem:', listItem)

    //   listItem.listCollections().then(collections => {
    //     console.log('*** collections:', collections)
    //   });
    // }

    // listItem.getCollections().then((querySnapshot) => {
    //   console.log('*** status:', querySnapshot)
    // });



    if (listItemData.createdAt && initFlag) {
      const createdAt = moment(listItemData.createdAt.seconds * 1000)
      const submitDate = createdAt.format("ddd DD MMM YYYY hh:mm:ss a")
      const fromNow = createdAt.fromNow()
      setLocalTimeState(submitDate + ' (' + fromNow + ')')
    }

    // TODO: Remove when m10 and under deprecated
    if (isRingSizesDash && 'phalangeTags' in formState && initFlag) {
      const leftActive = + formState.phalangeTags.slice(0, formState.phalangeTags.length / 2).some(tag => tag.stored)
      const rightActive = (+ formState.phalangeTags.slice(formState.phalangeTags.length / 2).some(tag => tag.stored)) + 1

      setInitFlag(false)

      setFormState(prevState => ({
        ...prevState,
        ringSizeCards: [{
          id: 0,
          name: formState.ringSizeFirstName,
          phalangeTags: formState.phalangeTags.reduce((arr, ptag, idx) => (ptag.stored ? arr.concat({id: idx, value: ptag.value}) : arr), []),
          activeHand: ['left', 'right', 'both'][leftActive + rightActive - 1]
        }]
      }))
    }
  }, [listItemData])

  return (
    <Card className={idx !== 0 ? 'mt-3' : ''} bg="light" text="dark">
      <Card.Header className="font-size-sm">
        <Row className="align-items-center">
          <Col md>
            <FontAwesomeIcon icon={listItemData.isMobile ? faMobileAlt : faLaptop} className={classModifier} />
            <FontAwesomeIcon icon={channelIcon} className={classModifier + ' ml-2'} />
            <span className={classModifier + ' pl-2 font-weight-bold'}>{copy.type[Pluralize(dashboardType, 1)].toUpperCase()}:</span>
            {listItemData.counterId && <strong className="pl-2">{listItemData.counterId}</strong>}
            {/* <span className="pl-2">{listItem.id}</span> */}
            {isPostsale && <span className="pl-2">{listItemData.receiptId}</span>}
            {isPurchases && listItemData.sendRingSizer && <FontAwesomeIcon icon={faRulerHorizontal} className="ng-secondary fa-lg ml-2" />}
            {/* <span className="pl-2">{listItemData.status}</span> */}
          </Col>
          <Col md>
            <StatusFormGroup
              dashboardType={dashboardType}
              listItem={listItem}
              listItemData={listItemData}
              listState={listState}
              setListState={setListState}
            />
            {/* <Form.Control
              as="select"
              size="sm"
              value={status}
              onChange={handleStateChange}
            >
              {Object.keys(states).map((keyName, i) => (
                <option key={`option${i}`} value={keyName}>{states[keyName]}</option>
              ))}
            </Form.Control>
            <Button size="sm" variant="ng-primary" className="ml-2" onClick={() => console.log('*** helo')}>
              <FontAwesomeIcon icon={faHistory} />
            </Button> */}
          </Col>
          <Col className="text-md-right mt-2 mt-md-0" md>
            {localTimeState}
          </Col>
        </Row>
      </Card.Header>

      <Card.Body>
        <Alert variant={classModifier} className="mt-0">
          {listItemData.details}
        </Alert>

        <Row>
          {/* <Col className="mt-3" lg={(isRingSizesDash && listItemData.ringSizeCards && listItemData.ringSizeCards.length > 1) ? 12 : 6}> */}
          <Col className="mt-3" lg={(isRingSizesDash && listItemData.ringSizeCards) ? (listItemData.ringSizeCards.length > 1 ? 12 : 6) : true}>
            <InfoCard type={dashboardType} section={section} formState={listItemData} />
          </Col>

          {isPurchases && listItemData.diffShippingInfo && <Col className="mt-3" lg>
            <InfoCard type={dashboardType} section="shipping" formState={listItemData} />
          </Col>}

          {/* {isRingSizesDash && <Col className="mt-3 mt-lg-0">
            <Card bg="light" text="dark" className="h-100">
              <Card.Header as="h6" className="InfoCard-header">
                <span>Medidas</span>
              </Card.Header>

              <Card.Body className="d-flex flex-column font-size-09">
                <RingSizeFormGroup formState={listItemData} setFormState={setFormState} isModal />
              </Card.Body>
            </Card>
          </Col>} */}

          {/* {isRingSizesDash
            && <Col
              className="mt-3"
              lg={(isRingSizesDash && listItemData.ringSizeCards && listItemData.ringSizeCards.length > 1) ? 12 : 6}
            >
              <RingSizeFormGroup formState={formState} setFormState={setFormState} isModal />
          </Col>} */}

          {isRingSizesDash && <RingSizeFormGroup formState={formState} setFormState={setFormState} isModal />}

          {(typeof listItemData.imageFiles !== 'number') && listItemData.imageFiles.map((receipt, idx) => (
            <Col key={`receipt${idx}`} className="mt-3" lg={2}>
              <Image src={receipt} onClick={() => {setShowImageModal(true)}} thumbnail />
            </Col>
          ))}
          <ImageModal showImageModal={showImageModal} setShowImageModal={setShowImageModal} imageFiles={listItemData.imageFiles} />
        </Row>

        {/* {(isRingSizesDash && listItemData.ringSizeCards && listItemData.ringSizeCards.length > 1) && <Row className="mt-3 mb-0">
          <Col>
            <RingSizeFormGroup formState={listItemData} setFormState={setFormState} isModal />
          </Col>
        </Row>} */}

      </Card.Body>
    </Card>
  )
}
