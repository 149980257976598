import React, { useState } from 'react'
import NavBarComponent from './NavBarComponent'
import DashboardList from './DashboardList'

export default function QuotesDashboard() {
  const [listState, setListState] = useState([])

  return (
    <>
      <NavBarComponent dashboardType="quotes" listState={listState} />
      <div className="NgDashboard">
        <DashboardList dashboardType="quotes" listState={listState} setListState={setListState} />
      </div>
    </>
  )
}
